import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/frontend/chameleon-docs/src/components/Layout/DefaultLayout.js";
import FigmaEmbed from 'components/FigmaEmbed';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h2 {...{
      "id": "available-image-ratios",
      "style": {
        "position": "relative"
      }
    }}>{`Available image ratio's`}</h2>
    <p>{`Below you can find the current available set of image ratio's.`}</p>
    <FigmaEmbed url="https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FChameleon-Best-Practices%3Fnode-id%3D285%253A70" mdxType="FigmaEmbed" />
    <h2 {...{
      "id": "avoidance-of-homogeneity",
      "style": {
        "position": "relative"
      }
    }}>{`Avoidance of homogeneity`}</h2>
    <p>{`During co-creation sessions with our news brands, we defined and agreed-upon a
`}<strong parentName="p">{`maximum of two image crop ratio's per brand`}</strong>{`. Ensuring both visual
consistency and workflow efficiency. Editorial staff teams don't have to worry
about awkward misplaced crops after uploading assets. This approach results in a
modular, but structured freedom in which brands can differentiate by choosing
ratio(s) that express their brand identity.`}</p>
    <FigmaEmbed url="https%3A%2F%2Fwww.figma.com%2Ffile%2FeVuNlIBZh5wbDiM4blDaEb%2FChameleon-Best-Practices%3Fnode-id%3D292%253A1183" mdxType="FigmaEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      